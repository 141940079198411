h1 {
  display: flex;
  justify-content: center;
  color:#e7e7e7
}

h2 {
  display: flex;
  justify-content: center;
  color:#eae0c2
}

h3 {
  display: flex;
  justify-content: flex-start;
  color:#faebff
}

a {
  color: #ccc2a6
}

.btn-div {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 10px;
}

.btn {
	box-shadow: 0px 1px 0px 0px #1c1b18;
	/* background:linear-gradient(to bottom, #eae0c2 5%, #ccc2a6 100%);
	background-color:#eae0c2; */
	border-radius:15px;
	border:2px solid #333029;
	display:inline-block;
	cursor:pointer;
	color:#505739;
	font-family:Arial;
	font-size:14px;
	font-weight:bold;
	padding:12px 16px;
	text-decoration:none;
	text-shadow:0px 1px 0px #ffffff;
  margin-left: 2%;
}

.btn-spec {
	box-shadow: 0px 1px 0px 0px #1c1b18;
	background:linear-gradient(to bottom, #c2e0ea 5%, #a6c3cc 100%);
	background-color:#c2dbea;
	border-radius:15px;
	border:2px solid #333029;
	display:inline-block;
	cursor:pointer;
	color:#505739;
	font-family:Arial;
	font-size:14px;
	font-weight:bold;
	padding:12px 16px;
	text-decoration:none;
  margin-left: 2%;
}

.btn:hover {
	background:linear-gradient(to bottom, #bad4ec 5%, #79a5c1 100%);
	background-color:#ffffff;
}

.btn-spec:hover {
	background:linear-gradient(to bottom, #bad4ec 5%, #79a5c1 100%);
	background-color:#ffffff;
}

.btn:active {
	position:relative;
	top:1px;
}

.chart-div {
  background-color: #ffffff;
  margin-left: 15%;
  margin-right: 15%;
}

.theory-div {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  color:#eae0c2
}

.vert-div {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  color:#f4f4f4
}

/*********** Baseline, reset styles ***********/
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 25rem;
}

/* Removes default focus */
input[type="range"]:focus {
  outline: none;
}

/******** Chrome, Safari, Opera and Edge Chromium styles ********/
/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
  background-color: #87b5fb;
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -4px; /* Centers thumb on the track */
  background-color: #5a5858;
  border-radius: 0.5rem;
  height: 1rem;
  width: 1rem;
}

input[type="range"]:focus::-webkit-slider-thumb {
  outline: 3px solid #5a5858;
  outline-offset: 0.125rem;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 20px;
  background-color: #5a5858;
  border-radius: 0.5rem;
  height: 0.5rem;
}

input[type=range]::-moz-range-thumb {
  outline-offset: 0.125rem;
}

.description-div {
  display: flex;
  flex-direction: column;
  color:#fbfbfb;
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 3%;
}

.text-div {
  display: grid;
  /* grid-auto-columns: minmax(0, 1fr); */
  /* grid-auto-flow: column; */
  color:#e6e6e6;
  margin-left: 15%;
  margin-right: 15%;
  /* margin-top: 20px; */
  /* gap: 15px */
}

.tangenty-div {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  color:#e6e6e6;
}

.single-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color:#e6e6e6;
}